<template>
  <v-container fluid>
    <v-card flat style="background-color: transparent;">
      <v-row no-gutters>
        <v-col cols="12">
          <div class="d-flex align-center pr-2" style="width: 100%">
            <!-- Left-aligned content -->
            <div class="d-flex align-center" style="flex-grow: 1;">
              <v-col v-if="tab !== 2" cols="6" class="mt-2 pb-0">
                <v-autocomplete
                  ref="processId"
                  v-model="processId"
                  outlined
                  dense
                  :items="processes"
                  :loading="isLoadingProcesses"
                  :search-input.sync="searchProcesses"
                  hide-no-data
                  clearable
                  item-text="name"
                  item-value="id"
                  class="pl-2"
                  :label="$lang.labels.process"
                  :placeholder="$lang.hints.selectProcessToFetchLogs"
                  prepend-inner-icon="mdi-cloud-search-outline"
                  style="min-width: 350px"
                >
                  <template slot="selection" slot-scope="data">
                    {{ data.item.name }}
                  </template>
                </v-autocomplete>
              </v-col>

              <v-col cols="2" class="pl-2 mt-2 pb-0">
                <v-datetime-picker
                  v-model="dateFrom"
                  :label="$lang.labels.from"
                  no-title
                  :text-field-props="{ outlined: true, dense: true }"
                >
                  <template v-slot:dateIcon="{}">
                    <v-icon>mdi-calendar</v-icon>
                  </template>
                  <template v-slot:timeIcon="{}">
                    <v-icon>mdi-clock-outline</v-icon>
                  </template>
                </v-datetime-picker>
              </v-col>

              <v-col cols="2" class="pl-2 mt-2 pb-0">
                <v-datetime-picker
                  v-model="dateTill"
                  :label="$lang.labels.to"
                  no-title
                  :text-field-props="{ outlined: true, dense: true }"
                >
                  <template v-slot:dateIcon="{}">
                    <v-icon>mdi-calendar</v-icon>
                  </template>
                  <template v-slot:timeIcon="{}">
                    <v-icon>mdi-clock-outline</v-icon>
                  </template>
                </v-datetime-picker>
              </v-col>
            </div>

            <!-- Right-aligned refresh button -->
            <div class="ml-auto d-flex align-center">
              <v-col cols="auto" class="pb-2 pl-0">
                <v-btn
                  text
                  light
                  color="primary"
                  @click="sendQuery()"
                >
                  <v-icon dense small>mdi-refresh</v-icon>
                </v-btn>
              </v-col>
            </div>
          </div>
        </v-col>
        <v-col cols="12">
          <div class="d-inline-flex  justify-space-between align-center pr-2 mt-2" style="width: 100%">
            <v-col v-if="tab !== 2" cols="4" class="pt-0 pl-0">
              <v-autocomplete
                v-model="status"
                hide-selected
                outlined
                dense
                clearable
                :items="formattedStatuses"
                hide-no-data
                class="pl-2"
                :label="$lang.labels.status"
                :placeholder="$lang.actions.startTyping"
                prepend-inner-icon="mdi-format-list-bulleted"
              ></v-autocomplete>
            </v-col>
            <v-col v-if="tab !== 2" cols="4" class="pt-0">
              <v-autocomplete
                v-model="triggerId"
                hide-selected
                outlined
                dense
                clearable
                :search-input.sync="searchTriggerId"
                :items="formattedTriggers"
                hide-no-data
                class="pl-2"
                :label="$lang.labels.triggerId"
                :placeholder="$lang.actions.startTyping"
                prepend-inner-icon="mdi-format-list-bulleted"
              ></v-autocomplete>
            </v-col>
            <v-col v-if="tab !== 2" cols="4" class="pt-0">
              <v-autocomplete
                v-model="type"
                hide-selected
                outlined
                dense
                clearable
                :items="formattedTypes"
                hide-no-data
                class="pl-2"
                :label="$lang.labels.type"
                :placeholder="$lang.actions.startTyping"
                prepend-inner-icon="mdi-format-list-bulleted"
              ></v-autocomplete>
            </v-col>
          </div>
        </v-col>
        <v-col cols="12" class="px-2">
          <v-alert
            dark
            color="secondary"
            :data-cy="$lang.hints.eventPermission"
          >{{ $lang.hints.eventPermission }}</v-alert>
        </v-col>
      </v-row>
      <v-divider class="my-2"></v-divider>

    </v-card>
    <v-tabs
      v-model="tab"
      class="custom-tab-design"
      background-color="transparent"
    >

      <v-tab
        :key="0"
        data-cy="tab-details"
        class="ml-2"
      >
        {{ $lang.labels.logs }}
      </v-tab>
      <v-tab
        :key="1"
        data-cy="tab-diagrams"
        class="ml-2"
      >
        {{ $lang.labels.diagrams }}
      </v-tab>
      <v-tab
        :key="2"
        data-cy="tab-statistics"
        class="ml-2"
      >
        {{ $lang.labels.statistics }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab" class="fill-height custom-bg pt-6">
      <v-tab-item :key="0" class="fill-height">
        <v-row dense no-gutters>
          <v-col cols="12  ">
            <v-data-table
              :headers="headers"
              :items="items.items"
              item-key="id"
              class="elevation-0 background-transparent "
              :loading="loading"
              :options.sync="options"
              :server-items-length="items.meta.totalItems"
              :footer-props="{
                'items-per-page-options': rowsPerPageItemsGlobal
              }"
            >
              <template v-slot:top> </template>

              <template v-slot:item.id="{ item }">
                <div class="font-weight-bold"># {{ item.id }}</div>
              </template>

              <template v-slot:item.instanceId="{ item }">
                <div class="d-flex justify-space-between align-center">
                  <div class="text-start name-container">
                    <div
                      v-if="String(item.instanceId).length < 86"
                      class="font-weight-regular color-primary text-decoration-none clickable-simple"
                      @click="goToLogInstanceStats(item.instanceId)"
                    >
                      {{ item.instanceId }}
                    </div>

                    <v-tooltip v-else top>
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          class="font-weight-regular color-primary"
                          v-bind="attrs"
                          @click="goToLogInstanceStats(item.instanceId)"
                          v-on="on"
                        >
                          {{ item.instanceId.substring(0, 62) }}...
                        </span>
                      </template>
                      <span>{{ item.instanceId }}</span>
                    </v-tooltip>
                  </div>
                </div>
              </template>

              <template v-slot:item.triggerId="{ item }">
                <div class="d-inline-flex">
                  <router-link
                    v-if="item.triggerId"
                    class="clickable font-weight-bold"
                    target="_blank"
                    :to="{
                      name: item.type === 'CRON' ? 'cronEdit' : 'restEdit',
                      params: { id: item.triggerId }
                    }"
                  >{{ item.triggerId }}</router-link>
                  <div
                    v-if="item.triggerId"
                    class="clickable ml-1 "
                    @click="
                      searchTriggerId = String(item.triggerId);
                      triggerId = String(item.triggerId);
                    "
                  >
                    {{ $lang.actions.filter }}
                  </div>
                </div>
              </template>

              <template v-slot:item.processId="{ item }">
                <div class="d-flex justify-space-between align-center">
                  <!-- Name on the left with a fixed or max width -->
                  <div class="d-flex text-start">
                    <router-link
                      class="text-decoration-none"
                      :to="{ name: 'processEdit', params: { id: item.processId } } "
                      target="_blank"
                    >
                      <div class="font-weight-bold">
                        {{ item.processId }}
                      </div>
                    </router-link>
                    <div
                      v-if="item.processId"
                      class="clickable-simple text-decoration-none color-primary ml-1"
                      @click="filterByProcessId(item.processId)"
                    >
                      {{ $lang.actions.filter }}
                    </div>
                  </div>
                </div>
              </template>

              <template v-slot:item.createdOn="{ item }">
                <div>{{ item.createdOn | formatDateTimePrecise }}</div>
              </template>

              <template v-slot:item.processingStartOn="{ item }">
                <div>{{ item.processingStartOn | formatDateTimePrecise }}</div>
              </template>

              <template v-slot:item.modifiedOn="{ item }">
                <div>{{ item.modifiedOn | formatDateTimePrecise }}</div>
              </template>

              <template v-slot:item.status="{ item }">
                <div>{{ $lang.status[item.status] }}</div>
              </template>

              <template v-slot:item.type="{ item }">
                <div>{{ $lang.status[item.type] }}</div>
              </template>

              <template v-slot:item.actions="{ item }">
                <div class="d-inline-flex">
                  <v-btn
                    color="primary"
                    :loading="item.isLoading"
                    class="mx-1 button-default-width"
                    @click="openEventModal(item)"
                  >{{ $lang.labels.event }}</v-btn>
                  <v-btn
                    color="primary"
                    class="ml-1"
                    @click="
                      goToLogsStep(item.processId, item.id, dateFrom, dateTill)
                    "
                  >{{ $lang.labels.steps }}</v-btn>
                  <v-btn color="info" class="mx-1" @click="goToProcess(item)">{{
                    $lang.labels.debug
                  }}</v-btn>
                </div>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item :key="1" class="fill-height custom-bg">
        <flowy-events-bar
          v-if="tab === 1"
          :options="optionsForChart"
          :total-items="items.meta.totalItems"
        />
      </v-tab-item>
      <v-tab-item :key="2" class="fill-height">
        <events-statistics
          v-if="tab === 2"
          :options="optionsForChart"
          :total-items="items.meta.totalItems"
          @openEvent="openEventModal($event)"
        />
      </v-tab-item>
    </v-tabs-items>
    <v-dialog
      v-if="showDetails"
      v-model="showDetails"
      max-width="1280"
      style="min-height: 80vh; max-height: 80vh;"
    >
      <log-cache-event-modal
        :event-id="String(eventId)"
        @closeDialog="eventId = ''; showDetails = false;"
      ></log-cache-event-modal>
    </v-dialog>
  </v-container>
</template>

<script>
import {
  getProcessesUsingGET as getProcesses,
  getProcessByIdUsingGET as getProcess,
  getInstancesUsingGET as getLogs
} from '@/utils/api'
import LogCacheEventModal from '../../components/ui/modals/LogCacheEventModal'
import FlowyEventsBar from '../../components/ui/events/FlowyEventsBar'
import EventsStatistics from './EventsStatistics'
import { mapState } from 'vuex'
import { bus } from '@/main'
import { format } from 'date-fns'
import { mapActions } from 'vuex'
import { definitions } from '@/utils/definitions'
import { debounce } from 'lodash'

export default {
  components: {
    LogCacheEventModal,
    FlowyEventsBar,
    EventsStatistics
  },
  props: {
    items: {
      type: Object,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showDetails: false,
      isLoadingProcesses: false,
      showTypeSelector: false,
      options: {},
      totalItems: 0,
      eventId: '',
      headers: [
        {
          text: this.$lang.labels.instanceId,
          align: 'start',
          sortable: true,
          value: 'instanceId'
        },
        { text: this.$lang.labels.createdOn, value: 'createdOn', sortable: true },
        {
          text: this.$lang.labels.processingStartOn,
          value: 'processingStartOn',
          sortable: true
        },
        { text: this.$lang.labels.modifiedOn, value: 'modifiedOn', sortable: true  },
        { text: this.$lang.labels['threadId'], value: 'threadId', sortable: true  },
        { text: this.$lang.labels.triggerId, value: 'triggerId', sortable: true  },
        {
          text: this.$lang.labels.processId,
          align: 'start',
          sortable: false,
          value: 'processId'
        },
        { text: this.$lang.labels.type, value: 'type', sortable: true },
        { text: this.$lang.labels.status, value: 'status', sortable: false },
        {
          text: this.$lang.labels.actions,
          value: 'actions',
          align: 'end',
          sortable: false
        }
      ],
      processes: [],
      searchProcesses: '',
      dateToday: new Date(),
      dateFrom: new Date(),
      dateTill: new Date(Date.now() + 3600 * 1000 * 24),
      processId: '',
      status: '',
      triggerId: '',
      searchTriggerId: '',
      type: '',
      lock: true,
      searchEventId: null,
      statusesArr: definitions.Event.properties.status.enum.sort(),
      typeArr: ['CRON', 'REST', 'RUN_PROCESS', 'MESSAGING', 'EVENT_HANDLER'].sort(),
      tab: 0,
      optionsForChart: {}
    }
  },
  computed: {
    ...mapState('app', [
      'rowsPerPageItemsGlobal',
      'globalTheme',
      'userSettings'
    ]),
    formattedStatuses() {
      return this.statusesArr.map((x) => {
        return { text: this.$lang.status[x], value: x }
      })
    },
    formattedTypes() {
      return this.typeArr.map((x) => {
        return { text: this.$lang.status[x], value: x }
      })
    },
    formattedTriggers() {
      return this.items && this.items.items && this.items.items.length > 0
        ? this.items.items
          .map((x) => String(x.triggerId))
          .filter((n) => n !== 'null' && n !== '')
        : []
    },
    computedDateFromFormatted() {
      return this.$options.filters.formatDate(this.dateFrom)
    },
    computedDateTillFormatted() {
      return this.$options.filters.formatDate(this.dateTill)
    }
  },
  watch: {
    formattedTriggers: {
      handler(val) {
        if (!val || val.length === 0) this.triggerId = ''
      }
    },
    searchProcesses: {
      handler: debounce( function (val) {
        if (val && val.length > 1) {
          this.searchProcessesFunction(val)
        }
      }, 500)
    },
    searchEventId: {
      handler(val) {
        if (!val) this.eventId = null
      }
    },
    options: {
      handler() {
        if (!this.lock) {
          this.sendQuery(false)
          this.savePreFill()
        }
      },
      deep: true
    },
    processId: {
      handler() {
        if (!this.lock) {
          this.sendQuery()
        }      }
    },
    dateFrom: {
      handler() {
        if (!this.lock) {
          this.sendQuery()
        }      }
    },
    dateTill: {
      handler() {
        if (!this.lock) {
          this.sendQuery()
        }      }
    },
    status: {
      handler() {
        if (!this.lock) {
          this.sendQuery()
        }      }
    },
    triggerId: {
      handler() {
        this.sendQuery()
      }
    },
    type: {
      handler() {
        if (!this.lock) {
          this.sendQuery()
        }
      }
    }
  },
  mounted() {
    bus.$on('refreshData', (name) => {
      if (!this.lock && name === this.$route.name) this.sendQuery()
    })
    if (!this.processId) this.$refs.processId.focus()
  },
  created() {
    if (this.userSettings.display.showId) {
      this.headers.splice(0, 0, { text: this.$lang.header.id, value: 'id', sortable: true })
    }

    this.dateFrom.setHours(0, 0, 0, 0)
    this.dateTill.setHours(23, 59, 59, 999)
    if (
      this.$route &&
      this.$route.query &&
      this.$route.query.processId &&
      this.$route.query.dateFrom &&
      this.$route.query.dateTill
    ) {
      this.lock = true
      this.eventId = this.$route.query.eventId ? this.$route.query.eventId : ''
      this.dateFrom = new Date(this.$route.query.dateFrom)
      this.dateFrom.setHours(0, 0, 0, 0)
      this.dateTill = new Date(this.$route.query.dateTill)
      this.dateTill.setHours(23, 59, 59, 999)
      this.processId = this.$route.query.processId
      if (this.$route.query.triggerId)
        this.triggerId = this.$route.query.triggerId
      this.options = {
        page: 1,
        itemsPerPage: 25,
        sortBy: ['createdOn'],
        sortDesc: [true]
      }
      this.loadPreFill()
    } else {
      if (localStorage.preFillData) {
        const preFill = JSON.parse(localStorage.preFillData)

        if (preFill && preFill.eventLogList) {
          this.lock = true
          this.eventId = preFill.eventLogList.eventId
          this.dateFrom = new Date(preFill.eventLogList.dateFrom)
          this.dateTill = new Date(preFill.eventLogList.dateTill)
          this.processId = preFill.eventLogList.processId
          this.status = preFill.eventLogList.status
          this.triggerId = preFill.eventLogList.triggerId
          this.type = preFill.eventLogList.type
          this.options = preFill.eventLogList.options
          this.loadPreFill()
        } else {
          this.options = {
            page: 1,
            itemsPerPage: 25,
            sortBy: ['createdOn'],
            sortDesc: [true]
          }
          setTimeout(() => {
            this.lock = false
            this.sendQuery()
          }, 50)
        }
      } else {
        this.options = {
          page: 1,
          itemsPerPage: 25,
          sortBy: ['createdOn'],
          sortDesc: [true]
        }
        setTimeout(() => {
          this.lock = false
          this.sendQuery()
        }, 50)
      }
    }
  },
  methods: {
    filterByProcessId(processId) {
      this.isLoadingProcesses = true
      getProcess({
        id: processId
      })
        .then((res) => {
          this.processId = ''
          this.processes = [res.data.data]
          this.isLoadingProcesses = false
          this.searchProcesses = res.data.data.name
          this.processId = processId
        })
        .catch((err) => {
          this.isLoadingProcesses = false
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })        })

    },
    async goToLogInstanceStats(instanceId) {
      const obj = {}

      obj.name = instanceId

      getLogs(obj)
        .then((res) => {
          const resultObject = res.data.data.items.find((x) => x.name === instanceId)

          if (resultObject) {
            const route = this.$router.resolve({
              name: 'logInstanceStats',
              params: {
                id: resultObject.id,
                lang: this.$lang.current_lang
              }
            })

            window.open(route.href, '_blank')
          }
        })
        .catch((error) => {
          this.addSnackbar({
            message: error,
            timeout: 5000,
            color: 'error'
          })
          console.log(error)
        })
    },
    ...mapActions('app', ['addSnackbar']),
    openEventModal(item) {
      this.eventId = item.id
      this.showDetails = true
    },
    goToProcess(item) {
      const route = this.$router.resolve({
        name: 'processEdit',
        params: { id: item.processId },
        query: { eventId: item.id }
      })

      window.open(route.href, '_blank')
    },
    savePreFill() {
      if (!localStorage.preFillData) localStorage.preFillData = '{}'
      if (localStorage.preFillData) {
        const preFill = JSON.parse(localStorage.preFillData)

        if (!preFill.eventLogList)
          preFill.eventLogList = {
            dateFrom: '',
            dateTill: '',
            processId: '',
            eventId: '',
            status: '',
            triggerId: '',
            type: '',
            options: {
              page: 1,
              itemsPerPage: 25,
              sortBy: [],
              sortDesc: []
            }
          }

        preFill.eventLogList.dateFrom = this.dateFrom
        preFill.eventLogList.dateTill = this.dateTill
        preFill.eventLogList.processId = this.processId
        preFill.eventLogList.eventId = this.eventId
        preFill.eventLogList.status = this.status
        preFill.eventLogList.triggerId = this.triggerId
        preFill.eventLogList.type = this.type
        preFill.eventLogList.options = { ...this.options, page: 1 }

        localStorage.preFillData = JSON.stringify(preFill)
      }
    },
    loadPreFill() {
      this.isLoadingProcesses = true
      if (this.processId === null || this.processId === '') {
        this.isLoadingProcesses = false
        setTimeout(() => {
          this.lock = false
          this.sendQuery()
        }, 50)

        return
      }
      getProcess({
        id: this.processId
      })
        .then((res) => {
          this.processId = ''
          this.processes = [res.data.data]
          this.isLoadingProcesses = false
          this.searchProcesses = res.data.data.name
          this.processId = res.data.data.id
          setTimeout(() => {
            this.lock = false
            this.savePreFill()
            this.sendQuery()
          }, 50)
        })
        .catch((err) => {
          this.isLoadingProcesses = false
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })        })
    },
    goToLogsStep(processId, eventId, dateFrom, dateTill) {
      this.$router.push({
        name: 'logs-steps',
        query: {
          processId,
          eventId,
          dateFrom: format(dateFrom, 'yyyy-MM-dd'),
          dateTill: format(dateTill, 'yyyy-MM-dd')
        }
      })
    },
    sendQuery(resetPage = true) {
      if (this.dateFrom && this.dateTill && !this.lock) {
        this.$emit('fetchLogs', {
          options: this.options,
          dateFrom: this.dateFrom,
          dateTill: this.dateTill,
          processId: this.processId,
          status: this.status,
          triggerId: this.triggerId,
          type: this.type,
          resetPage
        })
        this.optionsForChart = {
          options: this.options,
          dateFrom: this.dateFrom,
          dateTill: this.dateTill,
          processId: this.processId,
          status: this.status,
          triggerId: this.triggerId,
          type: this.type
        }
        this.savePreFill()
        if (this.tab === 1) this.tab = 0
        if (resetPage) this.options.page = 1
      }
    },
    searchProcessesFunction(val = '') {
      if (this.lock) return
      this.isLoadingProcesses = true
      getProcesses({
        name: val || ''
      })
        .then((res) => {
          if (res.status !== 200) {
            this.isLoadingProcesses = false

            return
          }
          this.processes = res.data.data.items
          this.isLoadingProcesses = false
        })
        .catch((err) => {
          this.isLoadingProcesses = false
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })        })
    }
  }
}
</script>
<style lang="scss" scoped>
.clickable {
  cursor: pointer;
  text-decoration: none;
  color: var(--v-primary-base);
}
</style>
