<template>
  <v-container fluid class="pa-2 ma-0 Chart">
    <v-switch v-model="hourToggle" label="Show By Hour" dense />
    <v-row
      wrap
      no-gutters
      align="center"
      justify="center"
    >
      <h4>{{ $lang.header.eventsByStates }}</h4>
      <v-col v-if="!loadingStates" cols="12">
        <Bar
          :chart-options="chartOptionsStates"
          :chart-data="chartDataStates"
          :chart-id="chartId"
          :dataset-id-key="datasetIdKey"
          :plugins="plugins"
          :css-classes="cssClasses"
          :styles="styles"
          :width="width"
          :height="height"
        />
      </v-col>
      <v-col v-else cols="12">
        <v-progress-circular indeterminate color="primary" />
      </v-col>
      <v-col cols="12" class="pt-2">
        <v-divider />
      </v-col>
      <h4 class="pt-2">{{ $lang.header.eventDurations }}</h4>
      <v-col v-if="!loadingStatesDurations" cols="12">
        <Bar
          :chart-options="chartOptionsStatesDurations"
          :chart-data="chartDataStatesDurations"
          :chart-id="chartId"
          :dataset-id-key="datasetIdKey"
          :plugins="plugins"
          :css-classes="cssClasses"
          :styles="styles"
          :width="width"
          :height="height"
        />
      </v-col>
      <v-col v-else cols="12">
        <v-progress-circular indeterminate color="primary" />
      </v-col>
      <v-col cols="12" class="pt-2">
        <v-divider />
      </v-col>
      <h4 class="pt-2">{{ $lang.header.eventInstances }}</h4>
      <v-col v-if="!loadingInstances" cols="12">
        <Bar
          :chart-options="chartOptionsInstances"
          :chart-data="chartDataInstances"
          :chart-id="chartId"
          :dataset-id-key="datasetIdKey"
          :plugins="plugins"
          :css-classes="cssClasses"
          :styles="styles"
          :width="width"
          :height="height"
        />
      </v-col>
      <v-col v-else cols="12">
        <v-progress-circular indeterminate color="primary" />
      </v-col>
      <v-col cols="12" class="pt-2">
        <v-divider />
      </v-col>
      <h4 class="pt-2">{{ $lang.header.eventStateTotals }}</h4>
      <v-col v-if="!loadingPie" cols="12" class="text-center pb-4">
        <pie-chart :chart-data="chartDataStatusesPie" />
      </v-col>
      <v-col v-else cols="12">
        <v-progress-circular indeterminate color="primary" />
      </v-col>
      <v-col cols="12" class="pt-2">
        <v-divider />
      </v-col>
      <h4 class="py-2">{{ $lang.labels.executions }}</h4>
      <v-col cols="12" class="text-center pb-4">
        <v-row
          wrap
          no-gutters
          align="center"
          justify="space-between"
        >
          <v-col cols="12" md="6" class="pr-md-2">
            <p class="pb-0 mb-0">{{ $lang.routes.triggers }}</p>
            <v-data-table
              :headers="triggerHeaders"
              :items="triggerIdArray"
              item-key="triggerId"
              class="elevation-0"
              hide-default-footer
              :items-per-page="-1"
              :sort-by="['count']"
              :sort-desc="[true]"
            >

              <template v-slot:item.trigger.name="{ item }">
                <div class="d-inline-flex">
                  <router-link v-if="item.trigger && item.trigger.id && item.routeName" class="clickable" :to="{ name: item.routeName, params: { id: item.trigger.id } }" target="_blank">{{ item.trigger.name }}</router-link>
                  <div v-else >Execute process trigger</div>
                </div>
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="12" md="6" class="pl-md-2 pt-2 pt-md-0">
            <p class="pb-0 mb-0">{{ $lang.routes.processes }}</p>
            <v-data-table
              :headers="processHeaders"
              :items="processIdArray"
              item-key="triggerId"
              class="elevation-0"
              hide-default-footer
              :items-per-page="-1"
              :sort-by="['count']"
              :sort-desc="[true]"
            >

              <template v-slot:item.process.name="{ item }">
                <div class="d-inline-flex">
                  <router-link v-if="item.process.id" class="clickable" :to="{ name: 'processEdit', params: { id: item.process.id } }" target="_blank">{{ item.process.name }}</router-link>
                  <div v-else >None</div>
                </div>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Bar } from 'vue-chartjs/legacy'
import {
  getStatesReportUsingGET as getStates,
  getTotalStatesReportUsingGET as getStatesTotal,
  getDurationUsingGET as getDuration,
  getPerInstanceUsingGET as getPerInstance
} from '@/utils/api'
// import ChartDataLabels from 'chartjs-plugin-datalabels'
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip, ArcElement } from 'chart.js'
import PieChart from './FlowyEventsPieChart'
import axios from 'axios'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  ArcElement
)

export default {
  name: 'BarChart',
  components: {
    Bar,
    PieChart
  },
  props: {
    chartId: {
      type: String,
      default: 'bar-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Array,
      default: () => []
    },
    options: {
      type: Object,
      default: () => {}
    },
    totalItems: {
      default: 0,
      type: Number
    }
  },
  data() {
    return {
      loadingPie: true,
      loading: true,
      loadingStates: true,
      loadingStatesDurations: true,
      loadingInstances: true,
      fetchedChartData: [],
      hourToggle: false,
      chartDataStates: {
        labels: [],
        datasets: []
      },
      chartOptionsStates: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            ticks: {
              stepSize: 1
            }
          }
        }
      },
      chartDataStatesDurations: {
        labels: [],
        datasets: []
      },
      chartOptionsStatesDurations: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            ticks: {
              stepSize: 1,
              callback: function(value, index, ticks) {
                return value + ' sec'
              }
            }
          }
        },
        plugins: {
          // datalabels: {
          //   align: 'end',
          //   anchor: 'end',
          //   backgroundColor: function(context) {
          //     return context.dataset.backgroundColor
          //   },
          //   borderRadius: 2,
          //   color: 'white',
          //   formatter: function(value) {
          //     return 'avg ' + value + ' sec'
          //   }
          // },
          tooltip: {
            callbacks: {
              footer: this.durationFooter,
              label: function(context) {
                let label = context.dataset.label || ''

                if (label) {
                  label += ': '
                }
                if (context.parsed.y !== null) {
                  label += 'avg ' + context.parsed.y + ' sec'
                }

                return label
              }
            }
          }
        }
      },
      chartDataInstances: {
        labels: [],
        datasets: []
      },
      chartOptionsInstances: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            ticks: {
              stepSize: 1
            }
          }
        }
      },
      chartDataTotals: {
        labels: [],
        datasets: []
      },
      chartOptionsTotals: {
        responsive: true,
        maintainAspectRatio: false
      },
      chartDataStatusesPie: {
        labels: [],
        datasets: []
      },
      chartOptionsStatusesPie: {
        responsive: true,
        maintainAspectRatio: false
      },
      statuses: ['SUCCESSFUL', 'NEW', 'IN_PROGRESS', 'FAILED', 'TIMEOUT', 'ON_HOLD', 'PROCESS_INACTIVE', 'FETCHED'].sort(),
      colorByStatus: {
        FAILED: '#FF5252',
        IN_PROGRESS: '#59bbbb',
        NEW: '#2196F3',
        ON_HOLD: '#696773',
        PROCESS_INACTIVE: '#FFC107',
        SUCCESSFUL: '#05c075',
        TIMEOUT: '#511127',
        FETCHED: '#9E9E9E'
      },
      colorByInstance: ['#59bbbb', '#2196F3', '#696773', '#FFC107', '#05c075', '#511127'],
      dataCountByStatus: {
        FAILED: [],
        NEW: [],
        ON_HOLD: [],
        PROCESS_INACTIVE: [],
        SUCCESSFUL: [],
        TIMEOUT: [],
        IN_PROGRESS: [],
        FETCHED: []
      },
      dataCountByStatusDurations: {
        FAILED: [],
        NEW: [],
        ON_HOLD: [],
        PROCESS_INACTIVE: [],
        SUCCESSFUL: [],
        TIMEOUT: [],
        IN_PROGRESS: [],
        FETCHED: []
      },
      dataCustomLabelsForDurations: {
        FAILED: [],
        NEW: [],
        ON_HOLD: [],
        PROCESS_INACTIVE: [],
        SUCCESSFUL: [],
        TIMEOUT: [],
        IN_PROGRESS: [],
        FETCHED: []
      },
      triggerIdArray: [],
      processIdArray: [],
      triggerHeaders: [
        {
          text: this.$lang.labels.executions,
          align: 'start',
          sortable: true,
          value: 'count'
        },
        {
          text: this.$lang.labels.name,
          align: 'start',
          sortable: true,
          value: 'trigger.name'
        }
      ],
      processHeaders: [
        {
          text: this.$lang.labels.executions,
          align: 'start',
          sortable: true,
          value: 'count'
        },
        {
          text: this.$lang.labels.name,
          align: 'start',
          sortable: true,
          value: 'process.name'
        }
      ]
    }
  },
  watch: {
    hourToggle() {
      this.fetchLogsForChart(this.options)
    }
  },
  created() {
    this.fetchLogsForChart(this.options)
  },
  methods: {
    resolveRouteNameFromTriggerType(type) {
      const triggerTypes = {
        CRON: 'cronEdit',
        REST: 'restEdit',
        MESSAGING: 'messagingEdit'
      }

      return triggerTypes[type]
    },
    async fetchExistingResource (type, id) {

      const resourcesTypes = {
        CRON: 'trigger-cron',
        REST: 'trigger-rest',
        MESSAGING: 'trigger-messaging',
        PROCESS: 'process',
        RUN_PROCESS: 'process'
      }

      const axiosUrl = `/api/${resourcesTypes[type]}/${id}`

      if (axiosUrl) {
        try {
          const res = await axios.get(axiosUrl)

          if (res.status !== 200 || !res.data?.data) {
            return false
          }

          return res.data?.data
        } catch (e) {
          console.log(e)

          return false
        }
      } else {
        return false
      }
    },
    groupAndCount(arr, prop) {
      return arr.reduce((acc, curr) => {
        if (!acc[curr[prop]]) {
          acc[curr[prop]] = { count: 0, type: curr.type }
        }
        acc[curr[prop]].count += 1

        return acc
      }, {})
    },
    groupByTriggerAndProcessId() {
      const groupedTrigger = this.groupAndCount(this.fetchedChartData, 'triggerId')
      const groupedProcess = this.groupAndCount(this.fetchedChartData, 'processId')

      const triggerIdArray = Object.entries(groupedTrigger).map(([id, val]) => ({ triggerId: +id, count: val.count, type: val.type })).map(async (row) => {
        return {
          ...row,
          triggerId: isNaN(row.triggerId) ? null : row.triggerId,
          trigger: row.type && row.triggerId ? await this.fetchExistingResource(row.type, row.triggerId) : null,
          routeName: row.type ? this.resolveRouteNameFromTriggerType(row.type) : null
        }})

      const processIdArray = Object.entries(groupedProcess).map(([id, val]) => ({ processId: +id, count: val.count })).map(async (row) => {
        return {
          ...row,
          process: row.processId ? await this.fetchExistingResource('PROCESS', row.processId) : null
        }})

      Promise.all(triggerIdArray)
        .then((res) => {
          this.triggerIdArray = res
        })
        // eslint-disable-next-line handle-callback-err
        .catch((err) => {
          console.log(err)
        })

      Promise.all(processIdArray)
        .then((res) => {
          this.processIdArray = res
        })
        // eslint-disable-next-line handle-callback-err
        .catch((err) => {
          console.log(err)
        })
    },
    durationFooter(tooltipItems) {

      return tooltipItems[0].dataset.customLabels[tooltipItems[0].dataset.labelRaw][tooltipItems[0].dataIndex]
    },
    formatStatusesPieByDay(obj) {
      this.loadingPie = true
      getStatesTotal(obj).then((statesResult) => {
        this.chartDataStatusesPie.datasets = []

        const labels = []
        const colors = []
        const data = []

        this.statuses.forEach((status) => {
          const filteredByStatus = statesResult.data.data.find((y) => y.status === status)

          if (filteredByStatus) {
            labels.push(`${this.$lang.status[status]} (${filteredByStatus.count})`)
            colors.push(this.colorByStatus[status])
            data.push(filteredByStatus.count)
          }
        })

        this.chartDataStatusesPie.labels = labels

        this.chartDataStatusesPie.datasets = [{
          label: 'Abc',
          backgroundColor: colors,
          data,
          hoverOffset: 4
        }]
        this.loadingPie = false
      })
    },
    formatByDay(obj) {
      this.loadingStates = true
      getStates(obj).then((dayResult) => {
        this.chartDataStates.datasets = []
        this.dataCountByStatus = {
          FAILED: [],
          NEW: [],
          ON_HOLD: [],
          PROCESS_INACTIVE: [],
          SUCCESSFUL: [],
          TIMEOUT: [],
          IN_PROGRESS: [],
          FETCHED: []
        }

        this.chartDataStates.labels = dayResult.data.data.map((x) => x.processingStartOn).sort()

        this.chartDataStates.labels = [...new Set(this.chartDataStates.labels)]

        const tempDataSets = []

        this.chartDataStates.labels.forEach((time) => {
          const tempTimeEntries = dayResult.data.data.filter((x) => x.processingStartOn === time)

          this.statuses.forEach((status) => {
            const howMany = tempTimeEntries.find((x) => x.status === status)

            this.dataCountByStatus[status].push(howMany ? howMany.count : 0)
          })

        })

        this.statuses.forEach((status) => {
          const filteredByStatus = dayResult.data.data.filter((y) => y.status === status)

          if (filteredByStatus && filteredByStatus.length > 0) {
            tempDataSets.push({
              label: this.$lang.status[status],
              stack: '0',
              backgroundColor: this.colorByStatus[status],
              data: this.dataCountByStatus[status]
            })
          }
        })

        this.chartDataStates.datasets = tempDataSets
        this.loadingStates = false
      })
    },
    formatByHour(obj) {
      this.loadingStates = true
      getStates(obj).then((hourResult) => {
        this.chartDataStates.datasets = []
        this.dataCountByStatus = {
          FAILED: [],
          NEW: [],
          ON_HOLD: [],
          PROCESS_INACTIVE: [],
          SUCCESSFUL: [],
          TIMEOUT: [],
          IN_PROGRESS: [],
          FETCHED: []
        }
        this.chartDataStates.labels = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '21', '22', '23']

        const tempDataSets = []

        this.chartDataStates.labels.forEach((time) => {
          const tempTimeEntries = hourResult.data.data.filter((x) => x.processingStartOn.length === 1 ? ('0' + x.processingStartOn) === time : x.processingStartOn === time)

          this.statuses.forEach((status) => {
            const howMany = tempTimeEntries.find((x) => x.status === status)

            this.dataCountByStatus[status].push(howMany ? howMany.count : 0)
          })

        })

        this.statuses.forEach((status) => {
          const filteredByStatus = hourResult.data.data.filter((y) => y.status === status)

          if (filteredByStatus && filteredByStatus.length > 0) {
            tempDataSets.push({
              label: this.$lang.status[status],
              stack: '0',
              backgroundColor: this.colorByStatus[status],
              data: this.dataCountByStatus[status]
            })
          }
        })

        this.chartDataStates.datasets = tempDataSets
        this.loadingStates = false
      })
    },
    formatDurationByDay(obj) {
      this.loadingStatesDurations = true
      getDuration(obj).then((dayResult) => {
        this.chartDataStatesDurations.datasets = []
        this.dataCountByStatusDurations = {
          FAILED: [],
          NEW: [],
          ON_HOLD: [],
          PROCESS_INACTIVE: [],
          SUCCESSFUL: [],
          TIMEOUT: [],
          IN_PROGRESS: [],
          FETCHED: []
        }
        this.dataCustomLabelsForDurations = {
          FAILED: [],
          NEW: [],
          ON_HOLD: [],
          PROCESS_INACTIVE: [],
          SUCCESSFUL: [],
          TIMEOUT: [],
          IN_PROGRESS: [],
          FETCHED: []
        }

        const formattedResult = []

        dayResult.data.data.forEach((item) => {
          item.durations.forEach((item2) => {
            formattedResult.push({
              ...item2,
              status: item.status
            })
          })
        })

        this.chartDataStatesDurations.labels = formattedResult.map((x) => x.date).sort()

        this.chartDataStatesDurations.labels = [...new Set(this.chartDataStatesDurations.labels)]

        const tempDataSets = []

        this.chartDataStatesDurations.labels.forEach((time) => {
          const tempTimeEntries = formattedResult.filter((x) => x.date === time)

          this.statuses.forEach((status) => {
            const howMany = tempTimeEntries.find((x) => x.status === status)

            if (howMany) {

              const noOfEvents = 1

              const customLabel = `${noOfEvents} events; min ${howMany.minTime} sec, max ${howMany.maxTime} sec`

              this.dataCustomLabelsForDurations[status].push(customLabel)

              this.dataCountByStatusDurations[status].push(howMany.average)
            }
          })

        })

        this.statuses.forEach((status) => {
          const filteredByStatus = formattedResult.filter((y) => y.status === status)

          if (filteredByStatus && filteredByStatus.length > 0) {
            tempDataSets.push({
              label: this.$lang.status[status],
              labelRaw: status,
              backgroundColor: this.colorByStatus[status],
              data: this.dataCountByStatusDurations[status],
              customLabels: this.dataCustomLabelsForDurations
            })
          }
        })

        this.chartDataStatesDurations.datasets = tempDataSets
        this.loadingStatesDurations = false
      })
    },
    formatInstancesByDay(obj) {
      this.loadingInstances = true
      getPerInstance(obj).then((perInstanceResult) => {
        this.chartDataInstances.datasets = []

        this.chartDataInstances.labels = perInstanceResult.data.data.map((x) => x.date).sort()

        const tempDataSets = []

        const instances = perInstanceResult.data.data.map((x) => x.instanceId)

        const newInstancesUnique = [...new Set(instances)]

        const instancesObj = {}

        this.chartDataInstances.labels.forEach((time) => {
          const tempTimeEntries = perInstanceResult.data.data.filter((x) => x.date === time)

          newInstancesUnique.forEach((instance) => {
            const howMany = tempTimeEntries.find((x) => x.instanceId === instance)

            if (!instancesObj[instance]) instancesObj[instance] = []

            instancesObj[instance].push(howMany ? howMany.count : 0)
          })

        })

        newInstancesUnique.forEach((instance, i) => {
          const filteredByStatus = perInstanceResult.data.data.filter((y) => y.instanceId === instance)

          if (filteredByStatus && filteredByStatus.length > 0) {
            tempDataSets.push({
              label: instance,
              backgroundColor: this.colorByInstance[i],
              data: instancesObj[instance]
            })
          }
        })

        this.chartDataInstances.datasets = tempDataSets
        this.loadingInstances = false
      })
    },
    fetchLogsForChart(options) {
      this.err = ''
      if (options) {
        if (options.dateFrom > options.dateTill) {

          return
        }
      }

      const obj = {
        dateFrom: options ? options.dateFrom : '',
        dateTill: options ? options.dateTill : ''
      }

      if (options && options.processId) {
        obj.processId = options.processId
      }

      if (options && options.status) {
        obj.status = options.status
      }

      if (options && options.triggerId) {
        obj.triggerId = options.triggerId
      }

      if (options && options.type) {
        obj.type = options.type
      }

      // this.loading = true

      obj.reportType = this.hourToggle ? 'HOUR' : 'DAY'

      if (this.hourToggle) {
        this.formatByHour(obj)
      } else {
        this.formatByDay(obj)
      }
      this.formatDurationByDay(obj)
      this.formatInstancesByDay(obj)
      this.formatStatusesPieByDay(obj)
      // this.groupByTriggerAndProcessId(obj)
    }
  }
}
</script>
<style lang="scss">
.Chart {
  background-color: var(--v-timelineBg-base) !important;
  border-radius: 5px;
  box-shadow: 0px 2px 15px rgba(25, 25, 25, 0.27);
}
</style>
